





import {Vue, Component} from "vue-property-decorator";
import SpeedDatingType from "./SpeedDating";

@Component({name: "SpeedDating"})
export default class SpeedDating extends Vue implements  SpeedDatingType{
}
